.basket__card{
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    padding: 20px;
    /* background-color: aliceblue; */
	box-shadow: 1px 20px 16px -17px rgba(69, 77, 73, 0.61);    
}

.basket__card__image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-color: grey;
}

.basket__card__image img{
    display: block;
    width: 100%;
    height: 110px;
    object-fit:contain;
}

.basket__card__col{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.basket__card__col button{
    align-items: center;
    background-color: #058f4a;
    border-radius: 10px;;
    width: 40px;
    height: 40px;
    font-size: 25px;
    font-weight: 900;
}

.basket__card__col__totalprice{
    margin: auto 30px;
    font-size: 18px;
}


.basket__card__title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
}

.BasketForm_all{
    display: flex;
    justify-content: center;
    width: 100%;
}

@media (max-width: 500px){
    .basket__card{
        flex-wrap: wrap;
        justify-content: center;
        gap:20px;
    }
    .basket__card__title{
        width: 100%;
    }
    .basket__card__title h2{
        font-size: 18px;
    }

}

@media (min-width: 500px) and (max-width: 810px){
    .basket__card{
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    .basket__card__title{
        width: 60%;
    }
    .basket__card__title h2{
        font-size: 18px;
    }

}
