.HighCatalog__Hiphop__cap{
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 10px;
}

.HighCatalog__Hiphop__cap__img{
    display: flex;
    width: 35%;
}

.HighCatalog__Hiphop__cap__img img{
    width: 100%;
    /* padding: 5% 5%; */
    border-top-left-radius: 15px;
    border-bottom-left-radius:  15px;

}

.HighCatalog__Classiccap__img{
    display: flex;
    width: 35%;
}

.HighCatalog__Classiccap__img img{
    width: 100%;
    /* padding: 5% 5%; */
    border-top-right-radius: 15px;
    border-bottom-right-radius:  15px;
}

.HighCatalog__cap__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 30%;
    padding: 0 2%;
}

.HighCatalog__cap__text__Hiphop{
    width: 100%;
    text-align: center;
    padding-left: 10px;
    height: 45%;
    top:50%;
    text-align: left;
    border-left: 2px solid #ccc;
}

.HighCatalog__cap__text__classic{
    display: flex;
    justify-content: right;
    align-content: center;
    width: 100%;
    height: 45%;
    font-size: 25px;
    padding-top: 15%;
    padding-right: 10px;
    text-align: center;
    font-size: 20px;
    border-right: 2px solid #ccc;
}

.HighCatalog__cap__text__classic p{
    margin: auto;
    /* padding: auto; */
    padding-right: 20px; 
    align-items: center;
}

.HighCatalog__all{
    display: flex;
    /* position: relative; */
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    margin: 10px auto;
}

.all h2{
    text-align: center;
    padding-bottom: 20px;
    
}

.HighCatalog__Hiphop__cap__img_1{
    display: flex;
    position: relative;
    cursor: pointer;
    width: 47%;
}

.HighCatalog__Hiphop__cap__img_1:hover{
    opacity: 0.6;
}
 
.HighCatalog__Hiphop__cap__img_1 img{
    border-radius: 15px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    /* opacity: 0.4; */
}

/* .HighCatalog__Hiphop__cap__img_1 img hover{
    opacity: 0.9;
} */

.HighCatalog__cap__text__Hiphop_1{
        display: flex;
        position: absolute;
        width: 200px;
        text-align: center;
        padding-left: 10px;
        top: 10px;
        left: 15px;
        height: 30%;
        text-align: left;
        border-left: 3px solid #ccc;
        font-size: 20px;
        background-color: rgb(41, 40, 40, 0.4);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        
}




.HighCatalog__Classic__cap__img_1{
    display: flex;
    position: relative;
    width: 47%;
    cursor: pointer;
}

.HighCatalog__Classic__cap__img_1:hover{
    opacity: 0.6;
}

.HighCatalog__Classic__cap__img_1 img{
    /* display: block; */
    border-radius: 15px;
    width: 100%;
    height: 300px;
    object-fit: cover;

}

.HighCatalog__cap__text__classic_1{
    display: flex;
    position: absolute;
    justify-content: right;
    align-items: center;
    width: 200px;
    height: 30%;
    top: 10px;
    right: 15px;
    padding-right: 10px;
    font-size: 20px;
    border-right: 3px solid #ccc;
    background-color: rgb(41, 40, 40, 0.4);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  
}

@media (max-width: 600px){
    .HighCatalog__all {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
    .HighCatalog__Classic__cap__img_1{
        /* margin-top: 15px; */
        width: 90%;
    }
    .HighCatalog__Classic__cap__img_1 img{
        height: 200px;
    }
    .HighCatalog__Hiphop__cap__img_1{
        width: 90%;
    }
    .HighCatalog__Hiphop__cap__img_1 img{
        height: 200px;

    }
    .HighCatalog__cap__text__Hiphop_1{
        width: 36%;
        height: 110px;
    }
    .HighCatalog__cap__text__Hiphop_1 p{
        margin-top: 5px;
    }


}

@media (min-width: 601px) and (max-width: 991px) {
    .HighCatalog__Hiphop__cap__img_1 img{
        height: 250px;
    }
    .HighCatalog__Classic__cap__img_1 img{
        height: 250px;
    }
}

