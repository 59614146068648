.slideC {
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    width: 362px;
    height: 272px;
    margin: 0 auto;
  }
  
  .slide {
    width: 362px;
    height: 272px;
    transition: transform 500ms ease 0s, opacity 500ms ease 0s,
      visibility 500ms ease 0s;
    position: absolute;
    top: 0;
    border-radius: 12px;
  }
  
  /* 3D reflection effect */
  .reflection {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: -60px;
    border-radius: 12px;
    transition: transform 500ms ease 0s, opacity 500ms ease 0s,
      visibility 500ms ease 0s;
  }
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sliderContent {
    width: 100%;
    height: 100%;
    display: flex;
    color: white;
    padding: 0px;
    align-items: center;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  
  .sliderContent h2 {
    margin: 16px 0;
  }
  .sliderContent p {
    margin: 0;
    margin-bottom: 16px;
  }
  
  /* normal css  */
  
  body {
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background: #111;
  }
  
  .center {
    margin-top: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 400px;
    padding-bottom: 10px;
  }
  
  .btns {
    display: flex;
    justify-content: center;
    max-height: 50px;
    padding-top: 50px;
    gap:100px;

  }
  
  .btns .btn {
    cursor: pointer;
  }
  
  .btns .btn:last-child {
    margin-left: 40px;
  }

  .sliderContent img {
    max-width: 100%;
    max-height: auto;
  }
  
  /* normal css  */
  