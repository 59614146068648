.CatalogChangeVisor img{
    width: 45px;
    height: 45px;
}

.CatalogChangeVisor div{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 100px;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(250, 246, 246, 0.25);
    -moz-box-shadow: 0px 0px 6px 3px rgba(246, 243, 243, 0.25);
    box-shadow: 0px 0px 6px 3px rgba(237, 236, 236, 0.25);
}

.CatalogChangeVisor div:hover{
    -webkit-box-shadow: 0px 0px 6px 3px rgba(250, 246, 246, 0.4);
    -moz-box-shadow: 0px 0px 6px 3px rgba(246, 243, 243, 0.4);
    box-shadow: 0px 0px 6px 3px rgba(237, 236, 236, 0.4);
}

.CatalogChangeVisor{
    display: flex;
    flex-direction: row;
    gap:20px;
}

.CatalogChange{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:100px;
}

.CatalogChangeColor{
    display: flex;
    align-items: center;
    justify-content: center;
}
.CatalogEmbroidery{
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    margin-top: 35px;
    padding: 20px;
    border-radius: 10px;
    color:Orange;
    font-size: 16px;
    background-color: rgba(237, 236, 236, 0.1);
}

.CatalogEmbroidery h2{
    width: 30%;
    color:Orange;
}
 
.CatalogEmbroidery div{
    color:Orange;
    width: 70%;
    /* min-width: 500px; */
}

.CardOption__title{
    display: flex;
    flex-direction: row;
    gap:50px;
    width: calc(100% + 100px); /* Делаем ширину на 20px с каждой стороны больше */
    margin-left: -50px; /* Сдвиг влево */
    margin-right: -50px; /* Сдвиг вправо */
    padding: 25px;
    /* height: 200px;  */
    background-color: #101010;
}
.CardOption__image{
    width: 300px;
}

.CardOption__image img{
    width: 600px;
    margin-left: -300px;
}
.CardOption__image__text{
    position: absolute; 
    padding-top: 190px;
    font-size: 37px;
    color:Orange;
}
.CardOption__text{
    display:flex;
    justify-content: center;
    margin-right: 50px;
}

@media (min-width: 661px) and (max-width: 1100px){
    .CatalogChange{
        flex-direction: column;
        gap: 0;
    }
    .CatalogChangeVisor div{
        margin-top: 0px;
    }
    .CardOption__title{
        /* display: none; */
        overflow: hidden;
        height: 340px;
        width: calc(100%); /* Делаем ширину на 20px с каждой стороны больше */
        margin-left: -25px; /* Сдвиг влево */
        margin-right: 0px; /* Сдвиг вправо */
        /* background-color: rgba (20,20,20, 0.7); */
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);

    }
    .CardOption__text{
        margin-right: 10px;
        /* margin-top: 70px; */
        align-items: center;
        text-wrap:wrap;
        font-size: 24px;
        /* display: none; */
    }
    .CardOption__image img{
        width: 500px;
        margin-left: -270px;;
    }
    .CardOption__image__text{
        padding-top: 150px;
        margin-left: -20px;
        font-size: 32px;
    }

    
}

@media (max-width: 660px){
    .CatalogEmbroidery{
        flex-direction: column;
        gap: 0;
    }
    .CatalogEmbroidery div{
        width: 100%;
        font-size: 14px;
    }
    .CatalogChange{
        flex-direction: column;
        gap: 0;
    }
    .CatalogChangeVisor div{
        margin-top: 0px;
    }
    /* .CardOption__text{
        margin-right: 20px;
        display: none;
    } */
    .CardOption__title{
        /* display: none; */
        overflow: hidden;
    }
}