body{
  /* max-width: 1180px; */
  margin: 0 auto;
  background-color: #101010;
  color: antiquewhite;
  box-sizing: border-box;
  overflow-y: auto;
  font-family: 'RobotoRegular'; 
  /* font-style: normal; */
  font-weight: 400;
  resize: none;
  align-items: center;
  list-style-type: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  /* Предотвращаем появление контекстного меню на устройствах Apple */
  -webkit-touch-callout: none;
}

button{
  cursor: pointer;
  border: none;
}


.container{
  max-width: 1180px;
  padding: 0 20px;
  margin: 0 auto;

}

