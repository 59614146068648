.basket__item{
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border-bottom: 2px, color(gray);
}

.basket__size__title{
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 30px;;
}




.basket__size h2{
    font-size: 25px;
}

.basket__null{
    margin: 50px auto;
}

.basket__size{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px;
}

.BasketForm{
    display: flex;
    z-index: 1;
    position: absolute;
    /* justify-content: center; */
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
}


.basket__button__form button{
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #058f4a;
    font-size: 20px;

}

.basket__button__form button:active{
    background-color: #124125;
}

.basket__button__form{
    display: flex;
    justify-content: center;
    
}