*{
    color: aliceblue;
}


.CatalogWrapper{
    max-width: 1180px;
    margin: 50px auto;
    padding: 20px 30px;
    background-color: #202020;
    -webkit-box-shadow: 0px 0px 11px 6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 11px 6px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 11px 6px rgba(0,0,0,0.75);
    padding-bottom: 50px;

    

}
/* .CatalogWrapper{
    padding-top: 5px;
    text-align: center;
    font-size: 25px;
} */

@media (max-width: 700px) {
    .CatalogWrapper {
      max-width: 100%;
    }
  }