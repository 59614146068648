.BackgroundSizeFooter{
    display: flex;
    text-align: center;
    bottom: 0;
    justify-content: center;
    height: 50px; 
    margin:50px auto;
    
    
    
}
