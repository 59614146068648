
.basketfom__title{
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    width: 100%;
}
.form__item p{
    font-size: 15px;
    color: black;
}

.basketfom__title form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    width: 350px;
    gap:10px;
    border-radius: 15px;
    background: whitesmoke;

}



.form__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    /* justify-content: space-between; */
    gap: 0px;
}

.form__input{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: black;
    font-size: medium;
    width: 250px;

}

.form__input p{
    font-size: 15px;
    color: black;
}

.form__submit-button button{
    margin-top: 30px;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #058f4a;
    border: none;
    font-size: 20px;
}
.form_send-result{
    margin-top: 10px;
    padding-bottom: 30px;;
    width: 100%;
    height: 100%;
    color: black;
    text-align: center;
    font-size: 20px;
}
.form__button__home{
    width: 150px;
    height: 40px;
    padding: auto;
    border-radius: 10px;
    background-color: #058f4a;
    border: none;
    font-size: 25px;
    text-align: center;
    display:none;
}