@font-face {
    font-family: "RobotoCondensed";
    src: local("RobotoCondensed"),
    url("../../fonts/RobotoCondensed/static/RobotoCondensed-Regular.ttf") format("truetype");
     /* url("../../fonts/RobotoCondensed/RobotoCondensed-VariableFont_wght.ttf") format("truetype"); */
    font-weight: normal;
    }


.Portfolio{
    /* z-index: -5; */
    margin-top: 25px;
    /* background-image: "/pic/bg-cp.jpg"; */
    background-image:url("bg-cp.jpg");
    height: 600px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    
}
.Portfolio__Mirror{
    /* z-index: 0; */
    width: 1200px;
    height: 460px;
    margin: 50px 0;
    /* background-color: rgba(78, 78, 78,  0.5); */
    background-color: rgba(255, 255, 255,  0.1);
    box-shadow: 0 25px 45px rgba(0, 0, 0,  0.4);
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255,  0.5);
    border-right: 2px solid rgba(255, 255, 255,  0.3);
    border-bottom: 2px solid rgba(255, 255, 255,  0.3);
    backdrop-filter: blur(10px);
}

.Portfolio__title{
    font-family: "RobotoCondensed", serif;
    margin: 40px 0 10px 30px;
    font-size: 64px;
    text-shadow: 0 10px 20px rgba(0, 0, 0,  0.7);
}
.Portfolio__title__cap{
    font-family: "RobotoCondensed", serif;
    font-size: 64px;
    margin-left: 230px;
    text-shadow: 0 10px 20px rgba(0, 0, 0,  0.7);
}
.Portfolio__title__text{
    font-family: "RobotoCondensed", serif;
    font-size: 22px;
    margin-top: 130px;
    margin-left: 350px;;
}

@media (min-width: 921px) and (max-width: 1210px){
    .Portfolio__Mirror{
        width: 95%;
        
    }
}

@media (min-width: 661px) and (max-width: 920px){
    .Portfolio__Mirror{
        width: 95%;
        height: 400px;
    }
    .Portfolio__title__text{
        margin-left: 20px;
        margin-top: 80px;
    }
}

@media  (max-width: 660px){
    .Portfolio{
        height: 520px;
    }
    .Portfolio__Mirror{
        width: 95%;
        height: 400px;
    }
    .Portfolio__title{
        font-size: 48px;
    }
    .Portfolio__title__cap{
        font-size: 48px;
        margin-left: 100px;
    }
    .Portfolio__title__text{
        font-size: 18px;
        margin-left: 10px;
        margin-top: 80px;
    }
}
