.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
    /* width: 400px; */
	/* padding: 0 2rem; */
	background-color: var(--mainColor);
	color: var(--textColor);
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
    /* width: 90px; */
}

nav a:hover {
	color: var(--secondaryColor);

}

.header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
    width: 5px;
}

.header div,
nav {
	display: flex;
	align-items: center;
}

.header__cont{
	cursor: pointer;
}

.header__contact{
	z-index: 2;
	background-color: rgba(49, 49, 49, 0.5);
	padding: 20px;
	backdrop-filter: blur(4px);
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	/* visibility: hidden; */
	top: 70px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.header__contact a{
	width: 100%;
	font-size: 20px;
}

.header__cont:hover .header__contact{
	visibility: visible;
	top:200px;
}

.header__contact__gram{
	display: flex;
	width: 211px;
	gap: 10px;
}
.header__contact__tel{
	height: 20px;
	gap: 25px;
	/* width: 100px; */
}
.header__contact__tel a{
	margin: 0;
}


@media only screen and (max-width: 1024px) {
	.header .nav-btn {
		visibility: visible;
		opacity: 1;
        width: 50px;
	}
    .header{
        width: 50px;
    }

	.header nav {
        z-index: 100;
		position: fixed;
		top: -200vh;
        /* top: -100%; */
		left: 0;
		height: 100%;
		width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: rgba(0, 0, 0, 0.85);
		backdrop-filter: blur(8px);
		transition: 1s;
	}

	.header .responsive_nav {
		/* display: flex; */
		transform: translateY(200vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a div{
		font-size: 1.5rem;
	}

	
	.header__contact{
		top:60%;
		background: none;
	}
}

@media (max-width: 600px){
	.nav a div{
		font-size: 16px;
	}
	.header__cont{
		overflow-x: hidden;
	}


}
