.hystory__title{
    margin-top: 50px;
}

.hystory__title__p{
    display: flex;
    justify-content: center;
    align-items: center;
}


.Hystory__title__info{
    /* width: 40%; */
    font-size: 13px;
    text-align: left;
    padding: 30px;
}

.hystory__title__div{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
    /* flex-direction: row; */
    
}

.Hystory__title__img{
    width: 500px;
}

@media (max-width: 850px) {
    .hystory__title__div{
        flex-direction: column-reverse;
    }

    .Hystory__title__info{
        width: 100%;
        padding: 0;
    }
    .Hystory__title__img{
        display: flex;
        /* position: relative; */
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .hystory__title{
        margin-top: 40px;
    }

}

@media (max-width: 500px) {
    .Hystory__title__img{
        width: 100%;
    }
    .Hystory__title__img img{
        object-fit: cover;
        width: 100%;
    }
}