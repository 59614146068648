@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

svg {
    justify-content: left;
	font-family: "Russo One", sans-serif;
	width: 100%; 
	height: 100%;
}
svg text {
	animation: stroke 5s infinite alternate;
	stroke-width: 2;
	stroke: #00ff80ce;
	font-size: 50px;
}
@keyframes stroke {
	0%   {
		fill:#2e2e2e; stroke:#00ff80ce;
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill: #1b1b1b; stroke:#00ff80ce; }
	80%  {fill: #1b1b1b; stroke:#00ff80ce; stroke-width: 3; }
	100% {
		fill:#00ff80ce; stroke: #1b1b1b;
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
	}
}

.BackgroundSize{
	background-color: black;
	box-shadow: 1px 20px 16px -17px rgba(69, 77, 73, 0.61);



}

.HeaderBig {
	max-width: 1180px;
	margin: 0 auto;
	/* position: relative; */
    width: 100%;
    height: 70px;
    display: flex;
    justify-content:space-between;
    /* align-items: center; */
	flex-direction: row;
}

.wrapper {
    justify-content: left;
	cursor: pointer;
	width: 350px;
    
    
}

    

.Navbar{
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 10px;
    margin-right: 15px;
    /* color: aliceblue; */
    font-size: 20px;
	height: 70px;
	
}

.Navbar__cart{
	position: relative;
	font-size: 20px;
}

.Navbar__cart__count{
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -10px;
	right: -10px;
	width: 20px;
	height: 20px;
	background-color: #ae00ff;
	border-radius: 10px;
	font-size: 11px;
}

.Navbar li{
	list-style-type: none;
}

.shop-cart-button{
	height: 30px;
	width: 30px;
	/* display: flex; */
	color: #00ff80ce;
	transition: color, transform 500ms ease;

}

.shop-cart-button:hover,
.shop-cart-button.active{
	color: #ae00ff;
	transform:scale(1.5);
	

}

.shop-cart{
	position: absolute;
	top: 70px;
	right: 0px;
	width: 400px;
	min-height: 200px;
	background: red;
}



/* меню мобильная */ 

@media (max-width: 477px){
	svg text {
		font-size: 35px;
	}

}
