.catalog__card__img{
    display: flex;
    flex-direction: row;
}

.catalog__card{
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    /* padding: 20px; */
    text-align: left;
    /* -webkit-box-shadow: 0px 0px 6px 3px rgba(250, 246, 246, 0.25); */
    /* -moz-box-shadow: 0px 0px 6px 3px rgba(246, 243, 243, 0.25); */
    /* box-shadow: 0px 0px 6px 3px rgba(237, 236, 236, 0.25); */
}

.catalog__card__option__title{
    display: flex;
    /* justify-content: left; */
    margin: 0;
    text-align: left;
}




.catalog__card__image{
    margin-top: 10px;
    width: 500px;
    height: 430px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: grey;
}

.catalog__card__image img{
    display: block;
    padding-left: 5%;
    width: 90%;
    height: 90%;
    object-fit: contain;
}

.catalog__card__image__mini{
    margin: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.catalog__card__image__mini1 img{
    border-top-right-radius: 15px;
}
.catalog__card__image__mini1{
    border-top-right-radius: 15px;
}

.catalog__card__image__mini4 img{
    border-bottom-right-radius: 15px;
}
.catalog__card__image__mini4{
    border-bottom-right-radius: 15px;
}

.catalog__card__image__mini img{
    background-color: grey;
    padding: 0 5px;
    display: block;
    width: 110px;
    height: 100px;
    object-fit: contain;
}

.catalog__card__image__mini div{
    width: 120px;
    height: 100px;
    background-color: grey;
}

.catalog__card__basket__button{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 40px; */
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #058f4a;
    font-size: 16px;
}

.catalog__card__option {
        width: 100%;
}

.catalog__card__option h2{
    width: 100%;
    font-size: 25px;
    /* font-weight: 600; */
}

.catalog__card__option__color{
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: center;
}

.catalog__card__option__color div{
    border-radius: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    
}

.catalog__card__option__full {
    font-size: 15px;
}

.catalog__card__option__full b{
    font-size: 18px;
}

.catalog__card__option__full__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.catalog__card__option__full__price{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-right: 50px;
}

.catalog__card__option__full__price__price{
    display: flex;
    font-size: 25px;
    align-items:last baseline;
    gap:10px;
}

.catalog__card__option__full_left{
    /* padding-top: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    width: 100%;
    gap:10px;
    margin-top: 20px;;
    
}

.ColorLiner{
    border: 1px;
    /* background: linear-gradient(-45deg, green 50%, red 50%) */
 
}

.Catalog__card__option__full_left__inBasket{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 40px; */
    width: 150px;
    height: 40px;
    border-radius: 10px;
    font-size: 15px;
    background-color: #ae00ff;
    cursor: pointer;
}

@media (max-width: 477px){
    .catalog__card{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 100px;
            
    }

    .catalog__card__image__mini1 img{
        border-top-left-radius: 15px;
    }
    .catalog__card__image__mini1{
        border-top-left-radius: 15px;
    }
    
    .catalog__card__image__mini4 img{
        border-top-right-radius: 15px;
    }
    .catalog__card__image__mini4{
        border-top-right-radius: 15px;
    }
    

    .catalog__card__image__mini img{
        width: 60px;
        height: 55px;
    }
    .catalog__card__image__mini div{
        width: 70px;
        height: 55px;
    }

    .catalog__card__image{
        width: auto;
        height: 250px;
    }
    .catalog__card__option{
        margin-top: 15px;
    }
    .catalog__card__option__full_left p{
        font-size: 14px;
    }
    .catalog__card__option__full_left b{
        font-size: 14px;
    }
    .catalog__card__option__full p{
        font-size: 14px;
    }
    .catalog__card__option__full b{
        font-size: 14px;
    }

    .catalog__card__option__color{
        flex-wrap:wrap;
        background: gray;
        padding-bottom: 10px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .catalog__card__option__full__price{
        margin-top: 0px;;
        margin-right: 0px;
        flex-direction: row;
        gap:15px;
    }

    .catalog__card__img{
        flex-direction: column-reverse;
    }
    .catalog__card__image__mini{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: grey;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin:0;
    }
    .catalog__card__image{
        border-top-left-radius: 0px;
        border-bottom-right-radius: 15px;
    }
    .catalog__card__option__color div{
        border-radius: 15px;
        width: 30px;
        height: 30px;
        
    }

}


@media (min-width: 477px) and (max-width: 660px){
    .catalog__card{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 100px;
            
    }
    .catalog__card__image__mini img{
        width: 60px;
        height: 55px;
    }
    .catalog__card__image__mini div{
        width: 70px;
        height: 55px;
    }
    .catalog__card__image{
        width: 400px;
        height: 250px;
    }
    .catalog__card__option__full_left p{
        font-size: 14px;
    }
    .catalog__card__option__full_left b{
        font-size: 14px;
    }
    .catalog__card__option__full p{
        font-size: 14px;
    }
    .catalog__card__option__full b{
        font-size: 14px;
    }

    .catalog__card__option__color{
        flex-wrap:wrap;
        /* margin-top: 15px; */
    }
    /* .catalog__card__image img{
        height: 70%;
    } */

}

@media (min-width: 900px) and (max-width: 1050px){
    .catalog__card__option__full__row{
        flex-direction: column;
    }
    .catalog__card__option__full__price{
        margin-right: 0px;
        flex-direction: column;
        margin-top: 5px;
        gap:10px
       
    }
}


@media (min-width: 660px) and (max-width: 900px){
    .catalog__card{
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    }        

}