
.basketfinish__title{
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    /* background: whitesmoke;  */
    width: 100%;

}

.baske__finish__border{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    width: 350px;
    gap:10px;
    border-radius: 15px;
    background: whitesmoke;
    /* padding-left: 30px; */
 

}
.basketfinish__title p{
    margin-top: 0;
    font-size: 15px;
    color: black;
}
.baske__finish__button{
    margin-top: 10px;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #058f4a;
    border: none;
    font-size: 25px;
    text-align: center;
    margin-bottom: 30px;
    cursor: pointer;
}

.baske__finish__border h3{
    margin-top: 40px;
    color: black;
}
.baske__finish__border p{
    margin-left: 20px;
}