.ColorSortDiv{
    margin-top: 50px;

}

.ColorChange{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:30px;
    justify-content: center;
}
.ColorChange div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(250, 246, 246, 0.25);
    -moz-box-shadow: 0px 0px 6px 3px rgba(246, 243, 243, 0.25);
    box-shadow: 0px 0px 6px 3px rgba(237, 236, 236, 0.25);
}

.ColorChange div:hover{
    -webkit-box-shadow: 0px 0px 6px 3px rgba(250, 246, 246, 0.5);
    -moz-box-shadow: 0px 0px 6px 3px rgba(246, 243, 243, 0.5);
    box-shadow: 0px 0px 6px 3px rgba(237, 236, 236, 0.5);
}
.ColorBlack{
    background: black;
}

.ColorNavy{
    background: linear-gradient(45deg, #2b86b0, #1C2E4C);
}

.ColorRed{
    background: linear-gradient(45deg, #dd3c9a, #7b0101);
}

.ColorGreen{
    background: linear-gradient(45deg, #b5e098, #02691d);
}

.ColorWhite{
    background: linear-gradient(45deg, #ffffff, #898a7d);
}

.ColorBrown{
    background: linear-gradient(45deg, #8e7449, #472601);
}

.ColorGray{
    background: linear-gradient(45deg, #858686, #2a2c2e);
}

.ColorBlack{
    background: linear-gradient(45deg, #2f3030, #000000);
}


.ColorHacky{
    background-image: url(../../product/img/camo.jpg)
}

.ColorSortDiv__text{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 550px){

}